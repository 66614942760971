export const UserEvents = {
  Click: {
    BUTTON_SEE_ALL_COLLECTIONS_ON_MAIN_PAGE:
      'CLICK_BUTTON_SEE_ALL_COLLECTIONS_ON_MAIN_PAGE',
    BUTTON_SEE_ALL_NFTS_ON_COLLECTION_PAGE:
      'CLICK_BUTTON_SEE_ALL_NFTS_ON_COLLECTION_PAGE',
    BUTTON_SEE_ALL_NFTS_ON_MAIN_PAGE: 'CLICK_BUTTON_SEE_ALL_NFTS_ON_MAIN_PAGE',
    CHOOSE_A_NETWORK_BUTTON_FROM_COLLECTIONS_PAGE:
      'CLICK_CHOOSE_A_NETWORK_BUTTON_FROM_COLLECTIONS_PAGE',
    CHOOSE_A_NETWORK_BUTTON_FROM_MAIN_PAGE:
      'CLICK_CHOOSE_A_NETWORK_BUTTON_FROM_MAIN_PAGE',
    CHOOSE_A_NETWORK_BUTTON_FROM_NFTS_PAGE:
      'CLICK_CHOOSE_A_NETWORK_BUTTON_FROM_NFTS_PAGE',
    COLLECTION_FROM_NFT_CARD: 'CLICK_COLLECTION_FROM_NFT_CARD',
    ON_COLLECTIONS_CARD_FROM_ACCOUNT_PAGE: 'CLICK_ON_COLLECTIONS_CARD_FROM_ACCOUNT_PAGE',
    ON_COLLECTIONS_OWNER_ACCOUNT_ON_COLLECTIONS_PAGE:
      'CLICK_ON_COLLECTIONS_OWNER_ACCOUNT_ON_COLLECTIONS_PAGE',
    ON_COLLECTIONS_OWNER_ACCOUNT_ON_COLLECTION_PAGE:
      'CLICK_ON_COLLECTIONS_OWNER_ACCOUNT_ON_COLLECTION_PAGE',
    ON_COLLECTIONS_OWNER_ACCOUNT_ON_TOKEN_PAGE:
      'CLICK_ON_COLLECTIONS_OWNER_ACCOUNT_ON_TOKEN_PAGE',
    ON_COLLECTION_IN_TABLE_ON_COLLECTIONS_PAGE:
      'CLICK_ON_COLLECTION_IN_TABLE_ON_COLLECTIONS_PAGE',
    ON_GRID_VIEW_NFTS: 'CLICK_ON_GRID_VIEW_NFTS',
    ON_LIST_VIEW_NFTS: 'CLICK_ON_LIST_VIEW_NFTS',
    ON_GRID_VIEW_COLLECTIONS: 'CLICK_ON_GRID_VIEW_COLLECTIONS',
    ON_LIST_VIEW_COLLECTIONS: 'CLICK_ON_LIST_VIEW_COLLECTIONS',
    ON_NFTS_TAB_FROM_ACCOUNT_PAGE: 'CLICK_ON_NFTS_TAB_FROM_ACCOUNT_PAGE',
    ON_NFT_CARD_ON_COLLECTION_PAGE: 'CLICK_ON_NFT_CARD_ON_COLLECTION_PAGE',
    OPEN_NFT_CARD_FROM_NFTS_PAGE: 'CLICK_OPEN_NFT_CARD_FROM_NFTS_PAGE',
    SEARCH_BUTTON_ON_ACCOUNT_PAGE: 'CLICK_SEARCH_BUTTON_ON_ACCOUNT_PAGE',
    SEARCH_BUTTON_ON_COLLECTIONS_PAGE: 'CLICK_SEARCH_BUTTON_ON_COLLECTIONS_PAGE',
    SEARCH_BUTTON_ON_MAIN_PAGE: 'CLICK_SEARCH_BUTTON_ON_MAIN_PAGE',
    SEARCH_BUTTON_ON_NFTS_PAGE: 'CLICK_SEARCH_BUTTON_ON_NFTS_PAGE',
    TAB_EXTENDED_ON_COLLECTION_PAGE: 'CLICK_TAB_EXTENDED_ON_COLLECTION_PAGE',
  },
};
